import * as React from 'react';
import Markdown from 'react-markdown';

const CertificatesContainer = ({ certificates }) => (
  <>
    <div
      className={`grid grid-cols-1 ${
        certificates.length < 4 ? (certificates.length < 2 ? 'lg:grid-cols-2' : 'lg:grid-cols-3') : 'lg:grid-cols-4'
      } gap-8`}
    >
      {certificates.map((el, i) => (
        <div key={i}>
          <div className={`text-5xl text-blue-600 ${el.description && 'mb-2.5'}`}>{el.title}</div>
          <div className="text-lg text-black">{el.description && <Markdown className="w-full markdown">{el.description}</Markdown>}</div>
        </div>
      ))}
    </div>
  </>
);

export default CertificatesContainer;
